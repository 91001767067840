@import '~antd/dist/antd.min.css';

.login-form {
  position: relative;
  top: 25%;
  max-width: 300px;
}

.login-form-button {
  width: 30%;
}
