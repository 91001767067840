.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @media print {
      #printPageButton {
          display: none;
      }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* 2021-04-19: Force text alignment to left. */
  .ant-form-item-label {
      text-align: left;
  }
  /* 2021-04-22: Border radius for Input component */
  .ant-btn /*.ant-btn-primary*/ {
      border-radius: 6px;
      margin: 6px;
      min-width: 70px;
  }
  
  .table-row-highlight {
      background-color:whitesmoke;
  }
  
  .infocard-gridstyle-label {
      width: 50%;
      text-align: left;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  
  .infocard-gridstyle-wrapper {
      width: 50%;
      text-align: left;
      padding: 10px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  
  .infocard-gridstyle-label-16pc {
      width: 16.66%;
      text-align: left;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
  }
  
  .infocard-gridstyle-wrapper-16pc{
      width: 16.66%;
      text-align: left;
      padding: 10px;
      /* font-weight: bold; */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  
  .infocard-gridstyle-wrapper-83pc{
      width: 83.33%;
      text-align: left;
      padding: 10px;
      /* font-weight: bold; */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  
  /*.transparent-button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
  }*/
  
  .player-wrapper {
      position: relative;
      padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
    
  .react-player {
      position: absolute;
      top: 0;
      left: 0;
  }
  
  .blink {
      animation: blinker 1s linear infinite;
  }
    
  @keyframes blinker {
      50% {
        opacity: 0;
      }
  }